import {USER_TYPE_PAGE, USER_TYPE_USER, ROLE_ADMIN, ROLE_MODERATOR, ROLE_ROOT} from "../../utils/index";
import * as jwtService from '../../services/jwt.service';

const jwtToken = jwtService.get();

const role = jwtToken
	? jwtToken['isRoot'] ? ROLE_ROOT : (jwtToken['isAdmin'] ? ROLE_ADMIN : ROLE_MODERATOR)
	: null;


const formatSubMenu = (index, baseMenu = []) => {
	let startIndex = Number.parseFloat(index);

	return baseMenu
		.filter(obj => {

			if(obj['myopinion_only'] && process.env.REACT_APP_SOCIAL_HUB) return false;

			if(obj['socialhub_only'] && !process.env.REACT_APP_SOCIAL_HUB) return false;

			switch (role) {
				case ROLE_ROOT:
					return obj['root'] === true;
				case ROLE_ADMIN:
					return obj['admin'] === true;
				case ROLE_MODERATOR:
					return obj['moderator'] === true;
			}
		})
		.map(obj => {
			const {root, admin, moderator, ...rest} = obj;
			startIndex = startIndex + 0.1;
			return {
				id: startIndex.toString(),
				...rest,
			}
		})
}

const formatMenu = (menu = []) => {

	let startIndex = 0;

	return menu
		.filter(obj => {

			if(obj['myopinion_only'] && process.env.REACT_APP_SOCIAL_HUB) return false;

			if(obj['socialhub_only'] && !process.env.REACT_APP_SOCIAL_HUB) return false;

			switch (role) {
				case ROLE_ROOT:
					return obj['root'] === true;
				case ROLE_ADMIN:
					return obj['admin'] === true;
				case ROLE_MODERATOR:
					return obj['moderator'] === true;
			}
		})
		.map(obj => {
			const {root, admin, moderator, socialhub, ...rest} = obj;
			startIndex = startIndex + 1;
			return {
				id: startIndex.toString(),
				...rest,
			}
		});
}

const metisMenu = formatMenu([
	{
		"icon": "icon-home",
		"label": "Tableau de bord",
		"to": "/",
		"root": true,
		"admin": true,
		"moderator": true,
	},

	{
		"icon": "icon-pie-chart",
		"label": "Statistiques",
		"content": formatSubMenu(2, [
			{
				"label": "Badges utilisateurs",
				"to": "/stats/users_badges",
				"root": true,
				"admin": true,
				"moderator": true,
			}
		]),
		"root": true,
		"admin": true,
		"moderator": true,
	},

	{
		"icon": "icon-paper-clip",
		"label": process.env.REACT_APP_SOCIAL_HUB ? (process.env.REACT_APP_APP_NAME || "Social Hub") : "MyOpinion",
		"content": formatSubMenu(3, [
			{
				"label": "Utilisateurs",
				"to": `/users?user_type=${USER_TYPE_USER}`,
				"root": true,
				"admin": true,
				"moderator": true,
			},
			{
				"label": "Pages",
				"to": `/users?user_type=${USER_TYPE_PAGE}`,
				"root": true,
				"admin": true,
				"moderator": true,
			},
			{
				"label": process.env.REACT_APP_SOCIAL_HUB ? "Posts" : "Opinions",
				"to": "/opinions",
				"root": true,
				"admin": true,
				"moderator": true,
			},
			{
				"label": process.env.REACT_APP_SOCIAL_HUB ? "Posts (boostés)" : "Opinions (boostées)",
				"to": "/opinions?boosted=1",
				"root": true,
				"admin": true,
				"moderator": true,
			},
			{
				"label": "Evénements",
				"to": "/events",
				"root": true,
				"admin": true,
				"moderator": true,
			},
			{
				"label": "Commentaires",
				"to": "/comments",
				"root": true,
				"admin": true,
				"moderator": true,
			},
			{
				"label": process.env.REACT_APP_SOCIAL_HUB ? "Groupes" : "Agoras",
				"to": "/agoras",
				"root": true,
				"admin": true,
				"moderator": true,
			},
			{
				"label": "Reports",
				"to": "/reports",
				"root": true,
				"admin": true,
				"moderator": true,
			},
			{
				"label": "Awards",
				"to": "/awards",
				"root": true,
				"admin": true,
				"moderator": true,
			},
			{
				"label": "Verifications",
				"to": "/verifications",
				"root": true,
				"admin": true,
				"moderator": true,
			},
			{
				"label": "Abonnements",
				"to": "/subscriptions",
				"root": true,
				"admin": true,
				"moderator": true,
			},
		]),
		"root": true,
		"admin": true,
		"moderator": true,
	},

	{
		"icon": "icon-users",
		"label": "Ressources humaines",
		"content": formatSubMenu(4, [
				{
					"label": "Soldes de conges",
					"to": "/hr/holidays_balance",
					"root": true,
					"admin": true,
					"moderator": true,
				},
			]),
		"root": true,
		"admin": true,
		"moderator": true,
		"socialhub_only": true
	},

	{
		"icon": "icon-lock",
		"label": "Securité",
		"content": formatSubMenu(5, [
			{
				"label": "Moderateurs",
				"to": "/moderateurs",
				"root": true,
				"admin": false,
				"moderator": false,
			},
			{
				"label": "Hubs",
				"to": "/hubs",
				"root": true,
				"admin": true,
				"moderator": false,
				"myopinion_only": true
			},
			{
				"label": "Autorisations",
				"to": "/authorizations",
				"root": true,
				"admin": true,
				"moderator": false,
				"socialhub_only": true
			},
			{
				"label": "Demandes d'autorisations",
				"to": "/authorization-requests",
				"root": true,
				"admin": true,
				"moderator": false,
				"socialhub_only": true
			},
			{
				"label": "Rapport d'activités",
				"to": "/logs",
				"root": true,
				"admin": true,
				"moderator": true,
			},
		]),
		"root": true,
		"admin": true,
		"moderator": true,
	},

	{
		"icon": "icon-settings",
		"label": "Paramètres",
		"to": "/settings",
		"root": true,
		"admin": true,
		"moderator": false,
	}
]);

export default metisMenu	